import CTALink from "@components/elements/CTALink"
import ArrowUpRightIcon from "@components/icons/ArrowUpRightIcon"
import React from "react"
import { Container } from "react-bootstrap"
import "./ContentLinks.scss"
import Animation from "@components/elements/Animation"

const ContentLinks = ({ module }) => {
  if (!module) return null

  const { title, heading, cards, file } = module
  return (
    <Animation animateInType="up" className="content-links-wrap section-m">
      <Container className="content-links-container">
        <div className="content-section">
          <p className="heading">{heading}</p>
          <h2 className="title">{title}</h2>
        </div>
        <div className="links-section">
          {cards?.map((card, i) => {
            if (!card) return null

            const { icon, description, cta, id } = card
            return (
              <Animation
                animateInType="up"
                delay={i * 200}
                wrapperClassName="link-item-wrap"
                className="link-item"
                key={id}
              >
                <div className="icon-section">
                  <img src={icon?.url} alt={icon?.alternativeText || "icon"} />
                </div>
                <div className="link-content">
                  {/* <CTALink cta={cta} className="link-title">
                    <span>{cta?.cta_label}</span>
                    <ArrowUpRightIcon />
                  </CTALink> */}
                  <CTALink file={file} cta={cta} className="link-title">
                    <span>{cta?.cta_label}</span>
                    <ArrowUpRightIcon />
                  </CTALink>
                  <p className="link-description">{description}</p>
                </div>
              </Animation>
            )
          })}
        </div>
      </Container>
    </Animation>
  )
}

export default ContentLinks
